import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  FunctionComponent,
} from 'react';
import { connectToChild } from '@trustshare/glados';
import { MethodInterface } from '@trustshare/glados/dist/types/common';
import { commonMethods, paramsToQuery, detectStorageAccess } from '../utils';
import {
  Token,
  CheckoutStatus,
  CheckoutState,
  UseCheckoutOpenParams,
} from '../types';
import useCheckout from '.';

type Props = {
  subdomain: string;
  onComplete?: (arg: CheckoutState) => void;
  onUpdate?: (arg: CheckoutState) => void;
} & UseCheckoutOpenParams;

let storageAccessPromise: Promise<boolean>;

const Checkout: FunctionComponent<Props> = (props) => {
  const [token, setToken] = useState<Token>(null);
  const [status, setStatus] = useState<CheckoutStatus>(
    'checkout_not_initiated'
  );
  const [paymentToken, setPaymentToken] = useState<string | null>(null);
  const [iframeSupport, setIframeSupport] = useState<boolean>(false);
  const [currHeight, setCurrHeight] = useState(null);
  const [opened, setOpened] = useState(false);
  const [loadingStoragePromise, setLoadingStoragePromise] = useState(true);

  const ref = useRef<HTMLIFrameElement>(null);
  const stateRef = useRef<CheckoutState>({ token, status, paymentToken });

  const { onComplete, subdomain, ...params } = props;

  const baseUrl = `https://${subdomain}.trustshare.co`;
  const url = `${baseUrl}/checkout${paramsToQuery(params)}`;

  useEffect(() => {
    storageAccessPromise = storageAccessPromise || detectStorageAccess(baseUrl);
    storageAccessPromise.then((val) => {
      setIframeSupport(val);
      setLoadingStoragePromise(false);
    });
  }, []);

  useEffect(() => {
    stateRef.current = { token, status, paymentToken };
    if (params.onUpdate) {
      params.onUpdate({ token, status, paymentToken });
    }
  }, [token, status, paymentToken]);

  useEffect(() => {
    let child: MethodInterface;
    if (iframeSupport && !loadingStoragePromise) {
      connectToChild('sdk', {
        ...commonMethods<CheckoutStatus>(setToken, setStatus, false),
        processComplete() {
          onComplete?.(stateRef.current);
        },
        setPaymentToken(token) {
          setPaymentToken(token);
        },
        setHeight(value) {
          if (currHeight === value) return;
          if (ref && ref.current) {
            ref.current.height = value + 'px';
          }
          setCurrHeight(value);
        },
        close() {
          child?.disconnect?.();
        },
      }).then((facade) => {
        child = facade;
      });
    }
    return () => {
      child?.disconnect?.();
    };
  }, [iframeSupport, loadingStoragePromise]);

  const { open } = useCheckout({ ...params, onClose: onComplete, subdomain });
  if (!iframeSupport && !opened && !loadingStoragePromise) {
    open();
    setOpened(true);
  }

  return iframeSupport ? (
    <iframe
      id="trustshare-checkout-iframe"
      ref={ref}
      src={url}
      style={{
        minWidth: '420px',
        border: 'none',
        minHeight: '200px',
      }}
    />
  ) : null;
};

export default Checkout;
