import { UseReleaseOpenParams, UseCheckoutOpenParams, Size } from './types';
import { Dispatch, SetStateAction } from 'react';

export function paramsToQuery(params: UseCheckoutOpenParams | UseReleaseOpenParams): string {
  const query = [];

  if (params.amount) {
    query.push('amount=' + params.amount);
  }

  if ('depositAmount' in params && params.depositAmount) {
    query.push('depositAmount=' + params.depositAmount);
  }

  if ('to' in params && params.to) {
    query.push('to=' + encodeURIComponent(params.to));
  }

  if ('from' in params && params.from) {
    query.push('from=' + encodeURIComponent(params.from));
  }

  if ('currency' in params && params.currency) {
    query.push('currency=' + params.currency);
  }


  if ('description' in params && params.description) {
    query.push('description=' + encodeURIComponent(params.description));
  }

  return query.length
    ? '?' + query.join('&')
    : '';
}

export function detectStorageAccess(baseUrl: string): Promise<boolean> {
  if (typeof document === 'undefined') return Promise.resolve(true);
  return new Promise(function (resolve) {
    const iframe = document.createElement('iframe');
    iframe.src = `${baseUrl}/_detector.html`;
    iframe.width = '1';
    iframe.height = '1';

    window.addEventListener('message', function detect(event) {
      if (event.data.type === 'detect') {
        iframe.parentNode?.removeChild?.(iframe);
        window.removeEventListener('message', detect)
        resolve(event.data.value);
      }
    });
    document.body.appendChild(iframe);
  });
}

export function calculatePopupPosition(size: Size): string[] {
  const a = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft;
  const i = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop;
  const g = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.documentElement.clientWidth;
  const f = typeof window.outerHeight != 'undefined' ? window.outerHeight : (document.documentElement.clientHeight - 22);
  const h = (a < 0) ? window.screen.width + a : a;
  const left = Math.floor(h + (g - size.width) / 2);
  const top = Math.floor(i + (f - size.height) / 2.5);
  return [ 'width=' + size.width, 'height=' + size.height, 'left=' + left, 'top=' + top ];
}

export const commonMethods = <T, U = string | null>(setToken: Dispatch<SetStateAction<U>>, setStatus: Dispatch<SetStateAction<T>>, isModal: boolean = false) => ({
  setToken(val: U) {
    setToken(val);
  },
  setStatus(val: T) {
    setStatus(val);
  },
  getReferrer() {
    return window.location.href;
  },
  getIsModal() {
    return isModal;
  }
});

export function parseAmount(amount: string | number| null | undefined): number | null | undefined {
  if (typeof amount !== 'string') return amount;
  return Number(amount.replace(/^0\.?0*|\D/g, ''));
}
