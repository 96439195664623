type Serializable =
  | string
  | number
  | boolean
  | null
  | RegExp
  | Serializable[]
  | { [property: string]: Serializable };

export type GladosMsg =
  | { kind: 'syn', secret: string, namespace: string }
  | { kind: 'syn_ack', secret: string }
  | { kind: 'ready', methods: string[] }
  | { kind: 'ready_ack', methods: string[] }
  | { kind: 'call', method: string; id: number; args: Serializable[] }
  | { kind: 'return', method: string; id: number; result: Serializable }
  | { kind: 'throw', method: string; id: number; error: string }

export type MethodInterface = {
  [key: string]: (...args: Serializable[]) => Promise<Serializable>;
  disconnect: () => Promise<null>;
};

export type Call = {
  method: string;
  resolve: (value?: any) => void;
  reject: (error?: Error) => void;
  id: number
};

export type LocalMethods = { [key: string]: (...args: any[]) => any | Promise<any> };

type InterfaceConstructionOptions = {
  methods: string[];
  inflightCalls: Map<number, Call>;
  remote: Window;
  origin: string;
  disconnect: () => Promise<null>;
};

export const constructInterface = ({
  methods,
  inflightCalls,
  remote,
  origin,
  disconnect,
}: InterfaceConstructionOptions): MethodInterface => {
  const facade: MethodInterface = { disconnect };
  let counter = 0;
  for (let m of methods) {
    facade[m] = function (...args: Serializable[]): Promise<Serializable> {
      return new Promise((resolve, reject) => {
        const id = ++counter;
        inflightCalls.set(id, { method: m, resolve, reject, id });
        remote.postMessage(
          { kind: 'call', method: m, args, id },
          origin
        );
        setTimeout(() => {
          inflightCalls.delete(id);
          reject(new Error(`Timeout on method: ${m} with callId: ${id}`));
        }, 5000);
      });
    }
  }
  return facade;
}
