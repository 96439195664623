import { useEffect, useRef, useState } from 'react';
import { connectToChild } from '@trustshare/glados';
import { MethodInterface } from '@trustshare/glados/dist/types/common';
import {
  CheckoutState,
  CheckoutStatus,
  Token,
  UseCheckout,
  UseCheckoutHookParams,
  UseCheckoutOpenParams
} from '../types';
import { calculatePopupPosition, commonMethods, detectStorageAccess, paramsToQuery } from '../utils';
import useModal from '../hooks/useModal';

let storageAccessPromise: Promise<boolean>;

function useCheckout(params: UseCheckoutHookParams): UseCheckout {
  if (typeof params.subdomain !== 'string') {
    throw new Error('You must provide a subdomain');
  }

  if (![ undefined, 'modal', 'popup' ].includes(params.format)) {
    console.warn('Invalid format supplied, default to modal');
    params.format = 'modal';
  }

  const [ token, setToken ] = useState<Token>(null);
  const [status, setStatus] = useState<CheckoutStatus>('checkout_not_initiated');
  const [ paymentToken, setPaymentToken ] = useState<string | null>(null);
  const stateRef = useRef<CheckoutState>({ token, status, paymentToken });

  let { format = 'modal' } = params;
  const { onClose, subdomain, format: _, ...parameters } = params;
  const baseUrl = `https://${subdomain}.trustshare.co`;

  useEffect(() => {
    stateRef.current = { token, status, paymentToken };
    if(params.onUpdate){
      params.onUpdate({ token, status, paymentToken })
    }
  }, [token, status, paymentToken]);

  const useCheckoutOnClose = () => {
    if (typeof onClose === 'function') {
      onClose?.(stateRef.current);
    }
    stateRef.current = { token: null, status: 'checkout_not_initiated', paymentToken: null }
    setToken(null);
    setPaymentToken(null);
    setStatus('checkout_not_initiated');
  };

  const { ref, closeModal, openModal } = useModal({
    onClose: useCheckoutOnClose
  });

  storageAccessPromise = storageAccessPromise || detectStorageAccess(baseUrl);

  const open = (params?: UseCheckoutOpenParams) => {
    const p = { ...parameters, ...params };

    if (typeof p.amount === 'string') {
      let amt = p.amount.replace(/^0\.?0*|\D/g, '');
      if (amt !== Number(amt).toString()) {
        throw new Error('Please provide a valid number');
      }
    }

    const url = `${baseUrl}/checkout${paramsToQuery(p)}`;
    switch (format) {
      case 'modal': {
        storageAccessPromise.then(value => {
          if (!value) {
            format = 'popup';
            return open(params);
          }

          let currHeight: number,
            child: MethodInterface;

          connectToChild('sdk', {
            ...commonMethods<CheckoutStatus>(setToken, setStatus, true),
            setPaymentToken(val) {
              setPaymentToken(val);
            },
            setHeight(value) {
              if (currHeight === value) return;
              currHeight = value;
              if (ref && ref.current) {
                ref.current.style.height = `${value}px`;
              }
            },
            close() {
              child?.disconnect?.();
              setTimeout(() => {
                closeModal();
              }, 100);
            }
          }).then((facade) => {
            child = facade;
          });

          return openModal({ url });
        });
        break;
      }
      case 'popup': {
        const options = calculatePopupPosition({
          width: 420, height: Math.min(682, window.innerHeight * 0.9)
        }).concat([
          'menubar=0',
          'toolbar=0',
          'location=0',
          'status=0',
          'resizable=1',
          'scrollbars=1',
        ]);

        let win: Window | null, child: MethodInterface;
        connectToChild('sdk', {
          ...commonMethods<CheckoutStatus>(setToken, setStatus),
          setPaymentToken(val) {
            setPaymentToken(val);
          },
          close() {
            child?.disconnect?.();
            setTimeout(() => win?.close?.(), 100);
          },
        }).then(facade => { child = facade; });
        win = window.open(url, 'trustshare', options.join());

        const interval = setInterval(() => {
          if (win?.closed) {
            clearInterval(interval);

            if (typeof onClose === 'function') {
              onClose(stateRef.current);
            }
          }
        }, 100);
        break;
      }
    }
  };

  return { token, status, paymentToken, open };
}

export default useCheckout;
