import { useEffect, useRef, useState } from 'react';
import { connectToChild } from '@trustshare/glados';
import { MethodInterface } from '@trustshare/glados/dist/types/common';
import {
  DisputeState,
  DisputeStatus,
  UseDispute,
  UseDisputeHookParams,
  UseDisputeOpenParams,
} from '../types';
import {
  calculatePopupPosition,
  commonMethods,
  detectStorageAccess,
} from '../utils';
import useModal from '../hooks/useModal';

let storageAccessPromise: Promise<boolean>;

function useDispute(params: UseDisputeHookParams): UseDispute {
  if (typeof params.subdomain !== 'string') {
    throw new Error('You must provide a subdomain');
  }

  if (![undefined, 'modal', 'popup'].includes(params.format)) {
    console.warn('Invalid format supplied, default to modal');
    params.format = 'modal';
  }

  const [token, setToken] = useState<string | null>(params.token || null);
  const [status, setStatus] = useState<DisputeStatus>('dispute_not_initiated');
  const [reason, setReason] = useState<string | null>(null);
  const stateRef = useRef<DisputeState>({
    token,
    status,
    reason
  });

  let { format = 'modal' } = params;
  const { onClose, subdomain, format: _, ...parameters } = params;
  const baseUrl = `https://${subdomain}.trustshare.co`;

  useEffect(() => {
    stateRef.current = { token, status, reason };
  }, [token, status, reason]);

  const useDisputeOnClose = () => {
    if (typeof onClose === 'function') {
      onClose?.(stateRef.current);
    }
    stateRef.current = {
      token: null,
      status: 'dispute_not_initiated',
      reason: null,
    };
    setToken(null);
    setReason(null);
    setStatus('dispute_not_initiated');
  };

  const { ref, closeModal, openModal } = useModal({
    onClose: useDisputeOnClose,
  });

  storageAccessPromise = storageAccessPromise || detectStorageAccess(baseUrl);

  const open = (params?: UseDisputeOpenParams) => {
    const p = { ...parameters, ...params };

    if (!p.token) {
      throw new Error('You must provide a token');
    } else {
      setToken(p.token);
    }

    const url = `${baseUrl}/escrow/${p.token}/dispute`;

    switch (format) {
      case 'modal': {
        storageAccessPromise.then((value) => {
          if (!value) {
            format = 'popup';
            return open(params);
          }

          let currHeight: number, child: MethodInterface;

          connectToChild('sdk', {
            ...commonMethods<DisputeStatus>(setToken, setStatus, true),
            setHeight(value) {
              if (currHeight === value) return;
              currHeight = value;
              if (ref && ref.current) {
                ref.current.style.height = `${value}px`;
              }
            },
            setReason(val: string) {
              setReason(val);
            },
            close() {
              child?.disconnect?.();
              setTimeout(() => {
                closeModal();
              }, 100);
            },
          }).then((facade) => {
            child = facade;
          });

          return openModal({ url });
        });
        break;
      }
      case 'popup': {
        const options = calculatePopupPosition({
          width: 420,
          height: Math.min(682, window.innerHeight * 0.9),
        }).concat([
          'menubar=0',
          'toolbar=0',
          'location=0',
          'status=0',
          'resizable=1',
          'scrollbars=1',
        ]);

        let win: Window | null, child: MethodInterface;
        connectToChild('sdk', {
          ...commonMethods<DisputeStatus>(setToken, setStatus),
          close() {
            child?.disconnect?.();
            setTimeout(() => win?.close?.(), 100);
          },
        }).then((facade) => {
          child = facade;
        });
        win = window.open(url, 'trustshare', options.join());

        const interval = setInterval(() => {
          if (win?.closed) {
            clearInterval(interval);

            if (typeof onClose === 'function') {
              onClose(stateRef.current);
            }
          }
        }, 100);
        break;
      }
    }
  };

  return { token, status, open, reason };
}

export default useDispute;
