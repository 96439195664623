import { useEffect, useRef, useState } from 'react';
import { connectToChild } from '@trustshare/glados';
import { MethodInterface } from '@trustshare/glados/dist/types/common';
import {
  ReleaseState,
  ReleaseStatus,
  UseRelease,
  UseReleaseHookParams,
  UseReleaseOpenParams
} from '../types';
import {
  calculatePopupPosition,
  commonMethods,
  detectStorageAccess,
  paramsToQuery,
  parseAmount,
} from '../utils';
import useModal from '../hooks/useModal';

let storageAccessPromise: Promise<boolean>;

function useRelease(params: UseReleaseHookParams): UseRelease {
  if (typeof params.subdomain !== 'string') {
    throw new Error('You must provide a subdomain');
  }

  if (![ undefined, 'modal', 'popup' ].includes(params.format)) {
    console.warn('Invalid format supplied, default to modal');
    params.format = 'modal';
  }

  const [ token, setToken ] = useState<string | null>(params.token || null);
  const [ amount, setAmount ] = useState<number | null>(parseAmount(params.amount) ?? null);
  const [ paymentToken, setPaymentToken ] = useState<string | null>(null);
  const [ status, setStatus ] = useState<ReleaseStatus>('release_not_initiated');
  const stateRef = useRef<ReleaseState>({
    token, amount, paymentToken, status
  });

  let { format = 'modal' } = params;
  const { onClose, subdomain, format: _, ...parameters } = params;
  const baseUrl = `https://${subdomain}.trustshare.co`;

  useEffect(() => {
    stateRef.current = { token, amount, paymentToken, status };
    if (params.onUpdate) {
      params.onUpdate({ token, amount, paymentToken, status });
    }
  }, [ token, amount, paymentToken, status ]);

  const useReleaseOnClose = () => {
    if (typeof onClose === 'function') {
      onClose?.(stateRef.current);
    }
    stateRef.current = {
      token: null, status: 'release_not_initiated', amount: null,
      paymentToken: null
    };
    setToken(null);
    setPaymentToken(null);
    setAmount(null);
    setStatus('release_not_initiated');
  };

  const { ref, closeModal, openModal } = useModal({
    onClose: useReleaseOnClose
  });

  storageAccessPromise = storageAccessPromise || detectStorageAccess(baseUrl);

  const open = (params?: UseReleaseOpenParams) => {
    const p = { ...parameters, ...params };

    if (!p.token) {
      throw new Error('You must provide a token');
    } else {
      setToken(p.token);
    }

    if (typeof p.amount === 'string') {
      let amt = p.amount.replace(/^0\.?0*|\D/g, '');
      if (amt !== Number(amt).toString()) {
        throw new Error('Please provide a valid number');
      }
    }

    const url = `${baseUrl}/escrow/${p.token}/release${paramsToQuery(p)}`;

    switch (format) {
      case 'modal': {
        storageAccessPromise.then(value => {
          if (!value) {
            format = 'popup';
            return open(params);
          }

          let currHeight: number,
            child: MethodInterface;

          connectToChild('sdk', {
            ...commonMethods<ReleaseStatus>(setToken, setStatus, true),
            setPaymentToken(value) {
              setPaymentToken(value);
            },
            setAmount(value) {
              setAmount(value);
            },
            setHeight(value) {
              if (currHeight === value) return;
              currHeight = value;
              if (ref && ref.current) {
                ref.current.style.height = `${value}px`;
              }
            },
            close() {
              child?.disconnect?.();
              setTimeout(() => {
                closeModal();
              }, 100);
            }
          }).then((facade) => {
            child = facade;
          });

          return openModal({ url });
        });
        break;
      }
      case 'popup': {
        const options = calculatePopupPosition({
          width: 420, height: Math.min(682, window.innerHeight * 0.9)
        }).concat([
          'menubar=0',
          'toolbar=0',
          'location=0',
          'status=0',
          'resizable=1',
          'scrollbars=1',
        ]);

        let win: Window | null, child: MethodInterface;
        connectToChild('sdk', {
          ...commonMethods<ReleaseStatus>(setToken, setStatus),
          setPaymentToken(value) {
            setPaymentToken(value);
          },
          setAmount(value) {
            setAmount(value);
          },
          close() {
            child?.disconnect?.();
            setTimeout(() => win?.close?.(), 100);
          },
        }).then(facade => {
          child = facade;
        });
        win = window.open(url, 'trustshare', options.join());

        const interval = setInterval(() => {
          if (win?.closed) {
            clearInterval(interval);

            if (typeof onClose === 'function') {
              onClose(stateRef.current);
            }
          }
        }, 100);
        break;
      }
    }
  };

  return { token, status, open, paymentToken, amount };
}

export default useRelease;
