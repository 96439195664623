import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';

type UseModal = {
  openModal: ({ height, url }: { height?: number | null, url: string }) => void;
  closeModal: () => void;
  ref: RefObject<HTMLDivElement>
}

export const useModal = ({ onClose }: { onClose: () => void }): UseModal => {

  const el = useMemo(() => {
    if (typeof window === 'undefined') return;
    return document.createElement('div');
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined' || !el) return;
    document.body.appendChild(el);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.removeChild(el);
      document.body.style.overflow = 'unset';
    };
  }, []);

  const ref = useRef<HTMLDivElement>(null);

  const onCloseModal = () => {
    onClose();
    el && ReactDOM.unmountComponentAtNode(el);
  };

  return {
    ref,
    closeModal: onCloseModal,
    openModal: ({ height, url }) => {
      if (!(process as any).browser || !el) return null;
      return ReactDOM.render(
        <div style={{
          width: '100vw',
          height: '100vh',
          pointerEvents: 'none',
          position: 'fixed',
          display: 'grid',
          alignItems: 'center',
          justifyItems: 'center',
          top: '0',
          background: 'rgba(255, 255, 255, 0.5)',
          zIndex: 9999
        }}>
          <div ref={ref} style={{
            width: '100%',
            height: height || '30vh',
            maxWidth: '420px',
            minHeight: '200px',
            maxHeight: '90vh',
            background: 'white',
            borderRadius: '1rem',
            overflow: 'hidden',
            boxShadow: '0 0 .5rem rgba(0, 0, 0, 0.25)',
            transition: 'height 0.4s ease-in-out',
          }}>

            <iframe
              id='trustshare__modal-iframe'
              src={url}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                top: '0',
                left: '0',
                pointerEvents: 'all'
              }}
              sandbox={[
                'allow-storage-access-by-user-activation',
                'allow-scripts',
                'allow-same-origin',
                'allow-forms',
                'allow-popups',
                'allow-popups-to-escape-sandbox'
              ].join(' ')}
            />
          </div>
        </div>
        , el);
    }
  };
};

export default useModal;
