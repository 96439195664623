import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connectToChild } from '@trustshare/glados';
import { MethodInterface } from '@trustshare/glados/dist/types/common';
import { commonMethods, detectStorageAccess } from '../utils';
import { DisputeState, DisputeStatus, UseDisputeOpenParams } from '../types';
import useDispute from '.';

type Props = UseDisputeOpenParams & {
  subdomain: string;
  onComplete?: (arg: DisputeState) => void;
  onUpdate?: (arg: DisputeState) => void;
  token: string;
};

let storageAccessPromise: Promise<boolean>;

const Dispute: FunctionComponent<Props> = (props) => {
  const [token, setToken] = useState<string>(props.token);
  const [status, setStatus] = useState<DisputeStatus>('dispute_not_initiated');
  const [reason, setReason] = useState<string | null>(null);
  const [iframeSupport, setIframeSupport] = useState<boolean>(false);
  const [currHeight, setCurrHeight] = useState(null);
  const [opened, setOpened] = useState(false);
  const [loadingStoragePromise, setLoadingStoragePromise] = useState(true);

  const ref = useRef<HTMLIFrameElement>(null);
  const stateRef = useRef<DisputeState>({ token, status, reason });

  const { onComplete, subdomain, ...params } = props;

  const baseUrl = `https://${subdomain}.trustshare.co`;
  const url = `${baseUrl}/escrow/${params.token}/dispute`;

  useEffect(() => {
    storageAccessPromise = storageAccessPromise || detectStorageAccess(baseUrl);
    storageAccessPromise.then((val) => {
      setIframeSupport(val);
      setLoadingStoragePromise(false);
    });
  }, []);

  useEffect(() => {
    stateRef.current = { token, status, reason };
    if (params.onUpdate) {
      params.onUpdate({ token, status, reason });
    }
  }, [token, status, reason]);

  useEffect(() => {
    let child: MethodInterface;
    if (iframeSupport && !loadingStoragePromise) {
      connectToChild('sdk', {
        ...commonMethods<DisputeStatus, string>(setToken, setStatus, false),
        processComplete() {
          onComplete?.(stateRef.current);
        },
        setHeight(value) {
          if (currHeight === value) return;
          if (ref && ref.current) {
            ref.current.height = value + 'px';
          }
          setCurrHeight(value);
        },
        setReason(val: string) {
          setReason(val);
        },
        close() {
          child?.disconnect?.();
        },
      }).then((facade) => {
        child = facade;
      });
    }
    return () => {
      child?.disconnect?.();
    };
  }, [iframeSupport, loadingStoragePromise]);

  const { open } = useDispute({ ...params, onClose: onComplete, subdomain });
  if (!iframeSupport && !opened && !loadingStoragePromise) {
    open();
    setOpened(true);
  }

  return iframeSupport ? (
    <iframe
      id="trustshare-dispute-iframe"
      ref={ref}
      src={url}
      style={{
        minWidth: '420px',
        minHeight: '200px',
        border: 'none',
      }}
    />
  ) : null;
};

export default Dispute;
