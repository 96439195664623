import {
  constructInterface,
  LocalMethods,
  MethodInterface,
  Call,
  GladosMsg
} from '../common';

export default function connect(namespace: string, methods: LocalMethods = {}): Promise<MethodInterface> {
  return new Promise((resolve) => {
    let childOrigin: string;
    const inflightCalls: Map<number, Call> = new Map();
    window.addEventListener('message', async function handler(event: MessageEvent<GladosMsg>) {
      if (childOrigin && event.origin !== childOrigin) return;

      if (
        event.source == null
        // Null coalescing to Number satisfy TS
        || (event.source instanceof (window.MessagePort ?? Number))
        || (event.source instanceof (window.ServiceWorker ?? Number))
      ) {
        console.error('Don\'t understand this window!');
        return;
      }

      switch (event.data.kind) {
        case 'syn':
          if (event.data.namespace !== namespace) return;
          childOrigin = event.origin;
          event.source.postMessage(
            { kind: 'syn_ack', secret: event.data.secret },
            childOrigin,
          );
          break;
        case 'ready':
          resolve(
            constructInterface({
              methods: event.data.methods,
              inflightCalls,
              remote: event.source,
              origin: childOrigin,
              disconnect: () => {
                window.removeEventListener('message', handler);
                return Promise.resolve(null);
              },
            })
          );
          event.source.postMessage(
            { kind: 'ready_ack', methods: Object.keys(methods ?? {}) },
            childOrigin,
          );
          break;
        case 'call':
          try {
            const result = await methods[event.data.method](...event.data.args);
            event.source.postMessage(
              {
                kind: 'return', method: event.data.method, id: event.data.id,
                result
              },
              childOrigin,
            );
          } catch (err) {
            event.source.postMessage(
              {
                kind: 'throw', method: event.data.method, id: event.data.id,
                error: err.message
              },
              childOrigin,
            );
          }
          break;
        case 'return': {
          const { id, method } = event.data;
          const inflight = inflightCalls.get(id);
          if (inflight) {
            inflight.resolve(event.data.result);
          } else {
            console.error(`Couldn't find an inflight call with id: ${id} for method: ${method}`);
          }
          break;
        }
        case 'throw': {
          const { id, method } = event.data;
          const inflight = inflightCalls.get(id);
          if (inflight) {
            inflight.reject(new Error(event.data.error));
          } else {
            console.error(`Couldn't find an inflight call with id: ${id} for method: ${method}`);
          }
          break;
        }
      }
    });
  });
};
