import React, { useEffect, useState, useRef, FunctionComponent } from 'react';
import { connectToChild } from '@trustshare/glados';
import { MethodInterface } from '@trustshare/glados/dist/types/common';
import {
  commonMethods,
  paramsToQuery,
  parseAmount,
  detectStorageAccess,
} from '../utils';
import {
  ReleaseStatus,
  ReleaseState,
  UseReleaseOpenParams,
} from '../types';
import useRelease from '.';

type Props = UseReleaseOpenParams & {
  subdomain: string;
  onComplete?: (arg: ReleaseState) => void;
  onUpdate?: (arg: ReleaseState) => void;
  token: string;
};

let storageAccessPromise: Promise<boolean>;

const Release: FunctionComponent<Props> = (props) => {
  const [ token, setToken ] = useState<string>(props.token);
  const [ status, setStatus ] = useState<ReleaseStatus>('release_not_initiated');
  const [ amount, setAmount ] = useState<number | null>(parseAmount(props.amount) ?? null);
  const [ paymentToken, setPaymentToken ] = useState<string | null>(null);
  const [ iframeSupport, setIframeSupport ] = useState<boolean>(false);
  const [ currHeight, setCurrHeight ] = useState(null);
  const [ opened, setOpened ] = useState(false);
  const [ loadingStoragePromise, setLoadingStoragePromise ] = useState(true)

  const ref = useRef<HTMLIFrameElement>(null);
  const stateRef = useRef<ReleaseState>({ token, status, paymentToken, amount });

  const { onComplete, subdomain, ...params } = props;

  const baseUrl = `https://${subdomain}.trustshare.co`;
  const url = `${baseUrl}/escrow/${params.token}/release${paramsToQuery(params)}`;

  useEffect(() => {
    storageAccessPromise = storageAccessPromise || detectStorageAccess(baseUrl);
    storageAccessPromise.then((val) => {
      setIframeSupport(val);
      setLoadingStoragePromise(false);
    });
  }, []);

  useEffect(() => {
    stateRef.current = { token, status, paymentToken, amount };
    if (params.onUpdate) {
      params.onUpdate({ token, status, paymentToken, amount });
    }
  }, [ token, status, paymentToken, amount ]);

  useEffect(() => {
    let child: MethodInterface;
    if (iframeSupport && !loadingStoragePromise) {
      connectToChild('sdk', {
        ...commonMethods<ReleaseStatus, string>(setToken, setStatus, false),
        processComplete() {
          onComplete?.(stateRef.current);
        },
        setAmount(amount) {
          setAmount(amount);
        },
        setPaymentToken(token) {
          setPaymentToken(token);
        },
        setHeight(value) {
          if (currHeight === value) return;
          if (ref && ref.current) {
            ref.current.height = value + 'px';
          }
          setCurrHeight(value);
        },
        close() {
          child?.disconnect?.();
        },
      }).then((facade) => {
        child = facade;
      });
    }
    return () => { child?.disconnect?.(); };
  }, [ iframeSupport, loadingStoragePromise ]);

  const { open } = useRelease({ ...params, onClose: onComplete, subdomain });
  if (!iframeSupport && !opened && !loadingStoragePromise ) {
    open();
    setOpened(true);
  }

  return (
    iframeSupport
    ?
      <iframe
        id="trustshare-release-iframe"
        ref={ref}
        src={url}
        style={{
          minWidth: '420px',
          minHeight: '200px',
          border: 'none',
        }}
      />
    :
      null
  );
};

export default Release;
